.card-button {
  display: flex;
  background: var(--card-button-bg-color);
  border: 0.1rem solid var(--card-button-border-color);
  margin: 0.625rem;
  height: 8.75rem;
  width: 16.25rem;
  color: var(--card-button-border-color);
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.selected {
  border: 0.1rem solid #8223d2;
}
