.dropdown {
  position: relative;
  display: inline-block;

  .dropdown-arrow {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    border-style: solid;
    border-color: var(--theme-text-color);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .dropdown-display {
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    .item {
      display: block;
      width: 100%;
      padding: 0.25rem 1rem;
      color: var(--theme-text-color);
      background-color: var(--dropdown-bg-color);
      &:hover {
        cursor: pointer;
        background-color: var(--theme-asurion-color);
        color: $white;
      }
    }
  }

  &:hover .dropdown-content {
    display: block;
  }
}
