.loading-skeleton {
  :not(:first-child) {
    margin-top: 1rem;
  }

  .small {
    width: 50%;
    height: 1.5rem;
  }

  .large {
    width: 100%;
    height: 1rem;
  }

  @keyframes gradient {
    0% {
      background-position-x: 0%;
    }
    100% {
      background-position-x: 100%;
    }
  }

  .gradient {
    --gradient-color1: transparent;
    --gradient-color2: var(--loading-skeleton-gradient-color);
    background-color: var(--gradient-color2);
    background: linear-gradient(
      90deg,
      var(--gradient-color1),
      var(--gradient-color2),
      var(--gradient-color1),
      var(--gradient-color2)
    );
    background-size: 300% 100%;
    animation: gradient 2s linear infinite reverse;
  }
}
